<template>
   <div class="m-work-details">
     <bannerStatic :num="5" />
     <div class="width800">
        <h3>{{contant.jobName}}</h3>
         <div class="job-info"><span>{{contant.workPlace}}</span><div class="lineDevider"></div><span>{{contant.salary}}</span></div>
        <div class="block-title">职位描述</div>
        <div class="block-contant" v-html="contant.jobdescription" >
        </div>
        <div class="block-title">职位要求</div>
        <div class="block-contant"  v-html="contant.jobClaim" >
        </div>
        <div class="block-title">工作地点：</div>
         <div class="block-contant"  v-html="contant.address" style="margin-bottom: .48rem" >
        </div>
        <p class="email">请将简历投送到邮箱：{{email}}</p>
        <router-link  :to="`/MWork`">查看更多职位</router-link>
     </div>
   </div>
</template>
<script>
import bannerStatic from'@/components/BannerStatic/mBannerStatic'
import  { newsImg } from '@/config/appSetting'
import { getJobDetails } from '@/api/data'
export default {
  name:'workDetails',
  components: {
    bannerStatic
  },
   data() {
      return {
        contant:{},
        email:''
      };
    },
    methods: {
  },
  mounted(){
    let _this=this
    this.email=sessionStorage.getItem("email")
    getJobDetails({},this.$route.params.id).then(res => {
        if (res.state) {
          _this.contant=res.data
        }
     })
  }
}
</script>
<style lang="less" scoped>
.m-work-details{
  .width800{
    background: #fff;
    text-align: left;
    margin:1.066667rem /* 80/75 */ .4rem /* 30/75 */;
    padding: .533333rem /* 40/75 */;
    h3 {
      font-size: .48rem /* 36/75 */;
      font-weight: 400;
      margin-bottom: 0px;
    }
    span{
        color: #666666;
        font-size: .32rem /* 24/75 */;
    }
    .block-title {
        margin: .4rem /* 30/75 */ 0;
        font-size: .373333rem /* 28/75 */;
        font-weight: 400;
    }
    .job-info {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      color: #646a73;
      font-size:.373333rem /* 28/75 */;
      font-weight: 500;
      margin-top: .2rem /* 15/75 */;
     }
     .lineDevider {
          display: inline-block;
          width: 1px;
          height:.266667rem /* 20/75 */;
          background-color: #bbbfc4;
          margin: 0 .2rem /* 15/75 */;
      }
    .email{
      color: #333333;
    }
  }
}
</style>



